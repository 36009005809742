.div-container {
  width: 50%;
  margin: 0 auto;
}

.btn-affiliate {
  font-size: 16px;
  height: 40px;
  border-radius: 25px;
  color: #fcfcfc;
  border: transparent;
  width: 100%;
  background: #e52d27;
  background: -webkit-linear-gradient(to right, #b31217, #e52d27);
  background: linear-gradient(to right, #b31217, #e52d27);
  box-shadow: 0px 2px 3px rgba(252, 249, 249, 0.1);
}

.nav-tabs-affiliate .nav-item.show .nav-link,
.nav-tabs-affiliate .nav-link.active {
  background: #007bff;
  border: none !important;
}

@keyframes wiggle {

  0%,
  7% {
    transform: rotateZ(0);
  }

  15% {
    transform: rotateZ(-15deg);
  }

  20% {
    transform: rotateZ(10deg);
  }

  25% {
    transform: rotateZ(-10deg);
  }

  30% {
    transform: rotateZ(6deg);
  }

  35% {
    transform: rotateZ(-4deg);
  }

  40%,
  100% {
    transform: rotateZ(0);
  }
}

.nav-tabs-affiliate>.nav-item>.nav-link {
  color: #fcfcfc;
  font-size: 16px;
  width: 100%;
}

.nav-tabs-affiliate {
  border-bottom: none;
  border: 1px solid #505050;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.nav-tabs-affiliate .nav-link:hover {
  color: gray;
}

.icon-bottom-navbar {
  animation: wiggle 2s linear infinite;
}

.text-manual {
  font-size: 18px;
  color: #fcfcfc;
}

.text-manual-item {
  font-size: 16px;
  color: #fcfcfc;
  margin-top: -7px;
}

.img-seo {
  width: 100%;
  border-radius: 15px;
}

.container-activity-guide {
  margin-top: 5.5rem;
  padding: 3rem 2rem;
  width: 100%;
  background-color: #222222;
}

.container-reward {
  margin-top: 5.5rem;
  padding: 3rem 2rem;
  width: 100%;
  // height: 100vh;
  background-color: #222222;
}

.container-affiliate {
  margin-top: 5.5rem;
  padding: 3rem 2rem;
  width: 100%;
  background-color: #222222;
}

.container-affiliate-detail {
  font-size: 18px;
  color: #fcfcfc;
  text-indent: 50px;
  letter-spacing: 0.02em;
}

.div-container-affiliate {
  width: 80%;
  margin: 0 auto;
}

.info_web {
  font-size: 12px;
  color: #bf8c26;
}

.div_affiliate_detail {
  background-color: #2e2e2ee0;
  padding: 15px 20px;
  border-radius: 12px;
  align-items: center;
}

.btn-home-affiliate {
  border-radius: 13px;
  padding: 5px 15px;
  border: none !important;
  color: #fcfcfc;
  background: -webkit-linear-gradient(to right, #e100ff, #7f00ff);
  background: linear-gradient(to right, #e100ff, #7f00ff);
}

.btn-rewards {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border-radius: 7px;
  border: none !important;
  padding: 7px 15px;
  color: #fcfcfc;
  // background: #7f00ff;
  // background: -webkit-linear-gradient(to right, #e100ff, #7f00ff);
  // background: linear-gradient(to right, #e100ff, #7f00ff);
}

.btn-reward-summit {
  height: 40px;
  width: 130px;
  border-radius: 5px;
  border: none;
  padding: 2px 15px;
  color: #fcfcfc;
  background: -webkit-linear-gradient(to right, #e100ff, #7f00ff);
  background: linear-gradient(to right, #e100ff, #7f00ff);
}

.btn-reward-close {
  height: 40px;
  width: 130px;
  border-radius: 5px;
  border: none;
  padding: 2px 15px;
  color: #000000;
  background: #ece9e6;
  background: -webkit-linear-gradient(to right, #ffffff, #ece9e6);
  background: linear-gradient(to right, #ffffff, #ece9e6);
}

.container-promotion-detail {
  margin-top: 5.5rem;
  padding: 3rem 2rem;
  width: 100%;
  background-color: #222222;
}

.div-container-promotion {
  width: 80%;
  margin: 0 auto;
}

.btn-promotion-detail {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border-radius: 7px;
  border: none !important;
  padding: 7px 15px;
  color: #fcfcfc;
  background: -webkit-linear-gradient(to right, #e100ff, #7f00ff);
  background: linear-gradient(to right, #e100ff, #7f00ff);
}

.image-promotion-detail {
  width: 50%;
}

.label-promotion-detail {
  color: #bf94ff;
}

.img-affiliate {
  width: 50%;
}

.text-reward-name {
  font-size: 18px;
  font-weight: bold;
  color: #9147ff;
}

.text-reward-info {
  font-size: 16px;
  margin-top: -8px;
}

.text-header-page {
  background: -webkit-linear-gradient(#4568dc, #b06ab3);
  // -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
}

.progress-bar-red {
  background: red;
}

.progress-bar .bg-warning {
  background-color: #e100ff;
}

.progress {
  background: black;
}

.bg-bg-red {
  background: #e52d27;
  background: -webkit-linear-gradient(to right, #b31217, #e52d27);
  background: linear-gradient(to right, #b31217, #e52d27);
  padding: 0 30px;
}

.bg-bg-yellow {
  background: #e65c00;
  background: -webkit-linear-gradient(to right, #f9d423, #e65c00);
  background: linear-gradient(to right, #f9d423, #e65c00);
  padding: 0 30px;
}

.bg-bg-green {
  background: #5433ff;
  background: -webkit-linear-gradient(to right, #a5fecb, #20bdff, #5433ff);
  background: linear-gradient(to right, #a5fecb, #20bdff, #5433ff);
  padding: 0 30px;
}

.text-details {
  font-size: 16px;
}

.card-reward {
  width: 400;
}

.btn-fixed2-active {
  width: 70px !important;
  height: 70px !important;
  font-size: 14px !important;
  border-radius: 15px !important;
  border: 0px !important;
  color: #fcfcfc !important;
  background: linear-gradient(to right, #0d6efd, #0096e3);
  background: -webkit-linear-gradient(to right, #9733ee, #da22ff);
}

.btn-fixed2-active-noborder {
  width: 70px !important;
  height: 70px !important;
  font-size: 16px !important;
  border-radius: 15px !important;
  background: #e3acf9 !important;
  color: white !important;
}

.btn-fixed2-active-noborder:hover,
.btn-fixed2-active-noborder:focus,
.btn-fixed2-active-noborder:active {
  background-color: #2d033b !important;
}

.col-20percent {
  display: block;
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.btn-fixed {
  width: 70px !important;
  height: 70px !important;
  font-size: 14px !important;
  border-radius: 15px !important;
  background: #fcfcfc;
  border: 0px !important;
  color: #000000 !important;
}

.btn-fixed:hover,
.btn-fixed:focus,
.btn-fixed:active {
  background-color: #0096e3 !important;
  color: #fff !important;
}

.btn-fixed-success {
  width: 70px !important;
  height: 70px !important;
  font-size: 16px !important;
  border-radius: 15px !important;
  background: #b19335;
  border: 0px !important;
  color: white !important;
  border: 3px solid #00a1ff !important;
}

.btn-fixed-success:hover,
.btn-fixed-success:focus,
.btn-fixed-success:active {
  background-color: #b19335 !important;
}

.btn-fixed2 {
  width: 70px !important;
  height: 70px !important;
  font-size: 16px !important;
  border-radius: 15px !important;
  background: #828387 !important;
  border: 0px !important;
  color: white !important;
}

.border-active {
  border: 4px solid #6f1ab6 !important;
}

.inpu-query {
  width: 350px;
}

.mt-3_5rem {
  padding-top: 6.3rem !important;
}

.contact_24h {
  color: #fff;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 25px;
  font-size: 20px;
}

.img_ccontact_24h {
  width: 128px;
  height: 128px;
  cursor: pointer;
}

.div_container_contract {
  padding-bottom: 15px;
}

.container_main {
  margin-top: 7rem !important;
  margin-bottom: 5rem !important;
  padding: 0px !important;
  position: relative;
  min-height: 75vh;
}

.div_container_main {
  padding: 0px;
}
.div_container_main_lao_only {
  padding: 0px;
}
.box_container_main {
  line-height: 20px;
  padding: 10px;
  background: white;
  font-size: 20px;
  border-radius: 0.25rem 0.25rem 0px 0px;
}

.btn_box_container {
  color: #fff !important;
  font-size: 16px !important;
  text-transform: unset !important;
}

.div_head_box_register {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-radius: 0.25rem !important;
}

.btn_submit5 {
  margin-top: 0.5rem;
  width: 100% !important;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  height: auto;
  border-radius: 0.25rem;
  color: #fcfcfc;
  border: transparent;
  background: linear-gradient(to right, #d63384, #6f42c1);
}

.btn_submit6 {
  margin-top: 0.5rem;
  width: 97% !important;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  height: auto;
  border-radius: 0.25rem;
  color: #fff;
  border: transparent;
  background: linear-gradient(to right, #3285ff, #3285ff);
}

.swiper-scrollbar {
  background: transparent !important;
}

.input_code {
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  text-align: center;
  height: 40px;
}

.btn_selected_code {
  width: 100%;
  border-radius: 7px;
  border: none !important;
  padding: 7px 15px;
  color: #fcfcfc;
  position: relative;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  overflow: hidden;
  display: grid;
  justify-content: center;
}

.btn_selected_code::after {
  background-color: rgb(255, 221, 30);
  border-radius: 0.5rem;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.2s transform ease-out;
  will-change: transform;
  z-index: -1;
}

.btn_selected_code:hover::after {
  transform: translate(0, 0);
}

.btn_selected_code:hover {
  border: 2px solid transparent;
  color: #000;
  transform: scale(1.05);
  will-change: transform;
}

.input_withdraw_max {
  height: 40px;
  border-radius: 0.25rem;
  border: none;
  background: #fffffff5;
}

.my_input_container {
  display: inline-block;
  position: relative;
}

.my_suffix {
  left: 0;
  top: 1px;
  color: #fffffff5;
  padding-left: 5px;
}

.img_free_code {
  width: 100px;
  height: 100px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .div-container {
    width: 90%;
  }

  .container-affiliate {
    margin-top: 2rem;
  }

  .container-promotion-detail {
    margin-top: 2rem;
  }

  .image-promotion-detail {
    width: 80%;
  }

  .img-affiliate {
    width: 100%;
  }

  .card-reward {
    width: 100%;
  }

  .col-20percent {
    display: block;
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .inpu-query {
    width: 100%;
  }
}

.div_infomation_personal {
  font-size: 17px;
  margin-top: 1rem !important;

  & p {
    margin-bottom: 0.5rem !important;
  }
}

.img_infomation_personal {
  width: 150px;
  height: auto;
}

.tab_panel_withdraw_deposit {
  padding: 10px !important;
  border: 1px solid #fff;
  border-radius: 0.5rem;
  margin-top: 1rem;
  background: #0E100D;
  transition: max-height .2s cubic-bezier(.4, 0, .2, 1);
}

.card_v3 {
  align-items: center !important;
  padding: 10px;
  color: #fff;
  background-size: cover;
  max-width: 100% !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  touch-action: manipulation;
  width: 100% !important;
  border: none;
  background-color: transparent !important;
  border-radius: 1.5rem;
  padding: 20px;
  margin-top: 0.5rem;
  box-shadow: 0 4px 15px 0 #313131;
  transition: transform .5s;
}

.card_v3:hover {
  transform: scale(1.1);
}

.div_img_withdraw_in_card {
  float: inline-end;

  & img {
    width: 50px;
    // border-radius: 50%;
  }
}

.grid_card_name_withdraw {
  // margin-top: 3rem !important;
}

.snowflake {
  color: #fff;
  font-size: 1em;
  text-shadow: 0 0 5px #3498db;
}

.inform_withdraw {
  display: flex;
  justify-content: center;
}

.page_div_update {
  margin-top: 0.5rem;

  & img {
    margin-top: 1rem;
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
}

.page_div_update>div {
  display: flex;
}

.page_div_update>div>div {
  margin-left: 0.5rem;
}

.txt_update {
  color: #fff;
  font-size: 18px;
  // text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500,
  //   0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
  // color: #fff6a9;
  text-align: center;
  animation: blink 12s infinite;
  -webkit-animation: blink 12s infinite;
}

.restart_credit {
  animation: laodingCicle 1s linear infinite;
}

.custom-modal3>div>.modal-content {
  background: transparent;
  border: none;
}

.custom-modal3>div>.modal-content>.modal-header {
  background: #7f00ff;
  // background: -webkit-linear-gradient(to right, #E100FF, #7F00FF);  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #E100FF, #7F00FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-bottom: none !important;
  color: #fcfcfc;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: 50px;
}

.custom-modal3>div>.modal-content>.modal-header>.modal-title {
  font-size: 18px;
}

.custom-modal3>div>.modal-content>.modal-body {
  background: #212529;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.custom_modal_promotion>div>.modal-content {
  position: relative;
  background: linear-gradient(180deg, #3e3e3e 0%, #010600 100%);
  border-radius: 20px;
  border: 3px solid #adadad;
  padding: 0.75rem 0.5rem 0.725rem 0.7rem;
  z-index: 3;
  animation: animatedgradient 3s ease alternate infinite;
}

.custom_modal_promotion>div>.modal-content>.modal-header {
  border-bottom: none !important;
  color: #fcfcfc;
}

.custom_modal_promotion>div>.modal-content>.modal-header>.modal-title {
  border-bottom: none !important;
  color: #fcfcfc;
  font-size: 18px;
  font-weight: 600;
}

.custom_modal_promotion>div>.modal-content>.modal-body {
  padding: 0px;
  background: transparent !important;
  // background: #212121;
  // border-bottom-left-radius: 15px;
  // border-bottom-right-radius: 15px;
}

.custom_modal_default>div>.modal-content {
  position: relative;
  background: linear-gradient(180deg, #3e3e3e 0%, #010600 100%);
  border-radius: 20px;
  border: 3px solid #adadad;
  padding: 0.75rem 0.5rem 0.725rem 0.7rem;
  z-index: 3;
  animation: animatedgradient 3s ease alternate infinite;
}

.custom_modal_default>div>.modal-content>.modal-header {
  border-bottom: none !important;
  color: #fcfcfc;
  padding: 5px;
  display: flex !important;
  justify-content: space-between !important;
}

.custom_modal_default>div>.modal-content>.modal-header>.modal-title {
  border-bottom: none !important;
  color: #fcfcfc;
  font-size: 18px;
  font-weight: 600;
}

.custom_modal_default>div>.modal-content>.modal-body {
  padding: 5px;
  background: transparent !important;
  // background: #212121;
  // border-bottom-left-radius: 15px;
  // border-bottom-right-radius: 15px;
}

.btn_changepass {
  background: #fff;
  color: #000;
  border: none;
  border-radius: 0.5rem;
  width: 100%;
}

.grid_txt_aff {
  display: flex;
  justify-content: end;
  color: #fff;
}

.btn_receive_commission {
  width: 100%;
}

.txt_nowarp {
  text-wrap: nowrap;
}

.p_affiliate_detail {
  font-size: 16px;
  color: #fcfcfc;
  text-indent: 50px;
  letter-spacing: 0.02em;
}

.txt_commission {
  color: #e9b033;
}

.btn_receive {
  width: 100%;
  border-radius: 0.5rem;
  color: #fff;
  background: #00a156;
  border: none;
}

.btn_wheel {
  border: none;
  border-radius: 0.5rem;
}

.div_txt_wheel {
  color: #fff;
}

.row_jk_wheel {
  display: flex;
  margin-top: 0.5rem;
  justify-content: center !important;
}

.box_wrapper {
  position: absolute;
  width: 80vw;
  max-width: 445px;
  height: 80vw;
  max-height: 445px;
  object-fit: contain;
  flex-shrink: 0;
  z-index: 5;
}

.inner_wrapper {
  position: absolute;
  width: 100%;
  inset: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.wheel_frame {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.div_center_wheel {
  position: absolute;
  width: 5rem;
  z-index: 5;
}

.gTLZXx {
  display: none !important;
  ;
}

.div_wheel_btn_back {
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
}

.btn_back_wheel {
  background: #6c757d;
  border: none;
}

.btn_submit_reward {
  width: 100%;
  border: none;
  background: #00a156;
}

.grid_modal_reward_history {
  // background: #fff;
  border-radius: 0.5rem;
}

.img_slice_menu {
  width: 22px;
  height: auto;
}

.div_current_rank {
  display: block;
  text-align: center;
  font-size: 14px;

  & p {
    margin-bottom: 0.5rem;
  }

  & Button {
    margin-top: 0.5rem;
    width: 100%;
  }
}

.div_current_rank_last {
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 7rem;
    height: auto;
  }

  & p {
    margin-bottom: 0;
  }

  .b_coin_rank {
    margin-left: 5px;
  }
}

.grid_receive_rank_last {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn_receive_rank_last {
  width: 40%;
}


.react-horizontal-scrolling-menu--item:first-child {
  // margin-left: 50px;
}


.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.div_rank_last_reaceive {
  display: block;
}

.img_rank_detail {
  width: 7rem;
  height: auto;
}
.img_rank_detail:hover{
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  transition: 0.8s ease;
  // animation: zoom 1.5s infinite !important;
}


.loader {
  margin: 0 auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);

  >div {
    height: 100%;
    width: 8px;
    display: inline-block;
    float: left;
    margin-left: 2px;
    -webkit-animation: delay 0.8s infinite ease-in-out;
    animation: delay 0.8s infinite ease-in-out;
  }

  .bar1 {
    background-color: #754fa0;
  }

  .bar2 {
    background-color: #09b7bf;
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  .bar3 {
    background-color: #90d36b;
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  .bar4 {
    background-color: #f2d40d;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  .bar5 {
    background-color: #fcb12b;
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  .bar6 {
    background-color: #ed1b72;
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
}


@-webkit-keyframes delay {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.05)
  }

  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes delay {

  0%,
  40%,
  100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.container_loader {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.txt_loader h1 {
  color: #f2d638;
  font-size: 16px;
  font-weight: 400;
  margin: 2px auto;
}
.loading {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading .line_box {
  padding: 2px;
  width: 226.3083451202px;
  // height: 20px;
  border: 2px solid #ffc107;
  border-radius: 20px;
}
.loading .line_box .line {
  width: 226.3083451202px;
  height: 16px;
  color: #f2d638;
  border: 2px solid;
  position: relative;
  filter: drop-shadow(0 0 10px #f2d638);

  border-radius: 20px;
  background: #f2d638;
  animation: loading 2s forwards cubic-bezier(0,0,0,0);
}

// DROP DOWN
.dropbtn {
  font-weight: bold;
  background-color: transparent;
  color: #00000073;
  padding: 0px;
  font-size: 16px;
  border: none;
  border-radius: .25rem;
  transition-duration: 500ms;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown_content_none {
  display: none;
}
.dropdown_content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 115px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  left: 0;
  border-radius: 0.5rem;
  color: #434343 !important;
  width: 140px;
  top: 28px;
}
.dropdown_content a {
  color: black;
  padding: 10px 8px;
  text-decoration: none;
  display: block;
}
.dropdown_content a:hover {
  background-color: #aaaaaa25;
  color: #000 !important;
  cursor: pointer;
  border-radius: 0.5rem;
}
.dropdown_content span {
  font-size: 14px;
}
.dropdown:hover .dropdown_content {
  display: block;
}
.dropbtn:hover {
  background-color: #aaaaaa25;
}
.div_profile_dashboard_container>span {
  color: #fff;
}
// END Dropdown
.img_icon_lang {
  width: 26px;
  height: auto;
  max-width: 100%;
}
.div_role_withdraw {
  ul {
    padding: 0px !important;
    list-style: none;
    li {
      font-size: 14px;
    }
  }
}
.div_helplink {
  background: linear-gradient(135deg,#5750de 0%,#f869d4 100%);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 13;
  top: 0;
  left: 0;
  background-size: cover;
}
.div_helplink_sec1 {
  padding: 2rem;
  text-align: center;
  width: 720px;
  z-index: 9999;
  position: relative;
  margin: 0 auto;
}
.img_help {
  width: 180px;
  height: auto;
  cursor: pointer;
}
.p_txt_web_name {
  font-size: 18px;
  color: #fff;
}
.div_box_help_container {
  margin-top: 2rem;
}
.div_box_help_top {
  border: 2px dashed #fff;
  margin-top: 1rem;
  padding: .3rem;
  border-radius: 0.5rem;
  animation: buzz 1.5s infinite;
}
.div_box_help {
  display: flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.1rem 1.3rem 0.1rem 0.5rem;
  cursor: pointer;
}
.div_box_help_sec2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img_help_link {
  width: 54px;
  min-width: 54px;
  height: 54px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border-radius: 6px;
}
.p_box_help {
  margin: 0 auto;
  margin-left: 10px;
}
.div_img_helplink {
  margin-top: 2rem;
  & img {
    border-radius: 0.5rem;
  }
}
.div_editor {
  padding: 10px;
  border-radius: 0.25rem;
  background: #fff;
}

.div_editor>p>a>img {
  width: 100%;
  height: auto;
}
.div_editor>p>img {
  width: 100%;
  height: auto;
}
.div_editor>p {
  text-align: center;
}
.cred_bold {
  color: #ff0018;
  font-weight: bold;
}
.div_editor>ul>li>img {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 768px) {
  .div_helplink_sec1 {
    width: auto;
  }
  .dropdown_content {
    min-width: 115px;
  }
  .dropbtn {
    font-size: 14px;
  }
  .loading .line_box .line {
    height: 14px;
  }
  .loading .line_box {
    width: 52%;
}
  .btn_receive_rank_last {
    width: 100%;
  }

  .row_jk_wheel {
    margin-top: 0.25rem !important;
  }

  .p_affiliate_detail {
    text-indent: 25px;
  }

  .tab_panel_withdraw_deposit {
    padding: 10px !important;
  }

  .box_container_main {
    border-radius: 0px !important;
  }

  .container_main {
    margin-top: 6rem !important;
    margin-bottom: 4rem !important;
  }

  .btn_submit6 {
    width: 90% !important;
  }

  .div_container_main {
    padding: 0px;
  }
  .div_container_main_lao_only {
    // padding: 0px 10px;
  }

  .img_ccontact_24h {
    width: 100px;
    height: auto;
  }

  .contact_24h {
    font-size: 16px;
  }

  .swiper-3d {
    perspective: none;
  }

  .info_web {
    font-size: 10px;
  }

  .div_affiliate_detail {
    padding: 15px;
    border-radius: 7px;
  }

  .container-affiliate-detail {
    font-size: 16px;
    text-indent: 25px;
  }

  .mt-3_5rem {
    padding-top: 6.3rem !important;
  }

  .item-withdrawHistory {
    font-size: 10px;
  }

  .div-container {
    width: 100%;
  }

  .div-container-affiliate {
    width: 100%;
    margin-top: 35px;
  }

  .container-activity-guide {
    margin-top: 2rem;
  }

  .image-tab-game {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .container-affiliate {
    margin-top: 2rem;
    padding: 20px;
  }

  .img-charactor {
    width: 90%;
  }

  .container-promotion-detail {
    margin-top: 2rem;
  }

  .div-container-promotion {
    width: 100%;
    margin-top: 35px;
  }

  .image-promotion-detail {
    width: 100%;
  }

  .img-affiliate {
    width: 100%;
  }

  .text-reward-name {
    font-size: 14px;
  }

  .text-reward-info {
    font-size: 12px;
  }

  .container-reward {
    margin-top: 2rem;
  }

  .text-details {
    font-size: 12px;
  }

  .card-reward {
    width: 100%;
  }

  .col-20percent {
    display: block;
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .inpu-query {
    width: 100%;
  }
}